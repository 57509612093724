/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import "lightmode";
@import '@angular/material/theming';
@import "../node_modules/mat-icon-button-sizes/styles/mat-icon-button-sizes";
@include mat.core();

@tailwind base;
@tailwind components;
@tailwind utilities;



html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

fa-icon {
  color: var(--primary-color);
}

body {
  margin: 0;
}

a {
  color: var(--accent-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card {
  margin: 2vh;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-content {
  height: 100%;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

mat-icon {
  color: var(--primary-color) !important;
  font-weight: bolder !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-disabled mat-icon{
  color: gray;
}

.mat-mdc-raised-button {
  background-color: #FFFFFF;
}


mat-expansion-panel-header {
  color: var(--primary-color);
  font-weight: bolder;

  font-size: xx-large;
}

.mat-drawer-container {
  //background-color: var(--primary-color);
  height: 100%;
}

.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background {
  background-color: #042e3f !important;
  border-color: #042e3f !important;
}

mat-toolbar-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

table {
  width: 100%;
}

.no-underline {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-underline {
    display: none;
  }
}

#deleteButton {
  background: linear-gradient(to left, var(--primary-color) 50%, var(--warn-color) 50%) right;
  background-size: 202%;
  padding-left: 0px;
  padding-right: 0px;

}

#deleteButton:active, #deleteButton span:active {
  background-position: left;
  transition: 1500ms ease-out;
}

#deleteButton:not(:active), #deleteButton span:not(:active) {
  background-position: right;
  transition: 500ms ease-out;
}

#deleteButton span {
  background: linear-gradient(to left, white 50%, white 50%) right;
  background-size: 202%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 10px;
  padding-top: 10px;
}




/*#deleteButton, #deleteButton:before {
  background: rgba(73,155,234,1);
  background: -moz-linear-gradient(45deg, rgba(73,155,234,1) 0%, rgba(26,188,156,1) 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(73,155,234,1)), color-stop(100%, rgba(26,188,156,1)));
  background: -webkit-linear-gradient(45deg, rgba(73,155,234,1) 0%, rgba(26,188,156,1) 100%);
  background: -o-linear-gradient(45deg, rgba(73,155,234,1) 0%, rgba(26,188,156,1) 100%);
  background: -ms-linear-gradient(45deg, rgba(73,155,234,1) 0%, rgba(26,188,156,1) 100%);
  background: linear-gradient(45deg, rgba(73,155,234,1) 0%, rgba(26,188,156,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#1abc9c', GradientType=1 );

}*/

#welcome-screen {
  margin-top: 10vh;
  height: 100%;
}

.welcome h1 {
  color: var(--primary-color);
  font-weight: bolder;
  font-size: xx-large;
}

#cloud {
  width: 28vh;
}


.icon-green {
  color: var(--primary-color);
}

.icon-yellow {
  color: #9a9a16;
}

.icon-red {
  color: #932525;
}

.icon-blue {
  color: #0e5b83;
}

.icon-gray {
  color: #9a9a9a;
}

.inline-icon {
  vertical-align: top;
  font-size: 18px !important;
}

mat-accordion {
  width: 100%;
}

.mat-icon {
  width: unset !important;
}


snack-bar-container {
  background: var(--primary-color);
}

.mat-mdc-simple-snack-bar {
  color: #FFFFFF;
}

.mat-mdc-snack-bar-action {
  color: #FFFFFF;
}

snack-bar-container.snackbar-error {
  background: red;
}

.snackbar-error  .mat-mdc-simple-snack-bar {
  color: #FFFFFF;
}

.snackbar-error .mat-mdc-snack-bar-action {
  color: #FFFFFF;
}

button.evaluation-date {
  background: var(--primary-color);
  border-radius: 100%;

  > * {
    color: #FFFFFF;
  }
}

button.evaluation-date {
  background: var(--primary-color);
  border-radius: 100%;

  > * {
    color: #FFFFFF;
  }
}

.closeButton{
  float: right;
  top:-18px;
  right:-18px;
  position: absolute;
}


  .dialog-hidden {
    display: none;
  }

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background-color:rgba(0, 0, 0, 0.3);

}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-right: white;
  border-right-width: 2px;
}

/* Track */
::-webkit-scrollbar, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9CA3AF;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white;
}


input {
    font-family: Inter, serif !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
button, mat-button-toggle, mat-chip, mat-chip-list, mat-chip-avatar, mat-label, mat-panel-title{
  font-family: Inter, serif !important;
}

.error-snackbar {
  @apply bg-red-700;
}


.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}


.custom-file-drop{
  height: 302px;
  width: 100%;
  background: #FFFFFF;
  color: #FFFFFF;
  border: 2px dashed #C6C9CB;
  border-radius: 12px;
  font-size: 20px;
}

.custom-file-drop-content{
  height: 196px;
  background: #FFFFFF;
  color: #fff;
  border: 0;
  border-radius: 12px;
  font-size: 20px;

}

.custom-file-drop-drag{
  height: 200px;
  width: 100%;
  background: #FFFFFF;
  color: #FFFFFFFF;
  border: 2px dashed rgba(0, 153, 153, 0.4);
  border-radius: 12px;
  font-size: 20px;
}

.custom-file-drop-content-drag{
  height: 196px;
  background: rgba(0, 153, 153, 0.15);
  color: #FFFFFFFF;
  border: 0;
  border-radius: 12px;
  font-size: 20px;

}

.mat-dialog-container {
  padding: 0 !important;
}

.dense-1 {
  @include mat.all-component-densities(-3);
}


/*.custom-file-drop-check{
  width: 100%;
  background: #FFFFFF;
  color: #EDF2F4;
  border: 2px dashed #6b7280;
  border-radius: 12px;
  font-size: 20px;
}

.custom-file-drop-check-content{
  background: #FFFFFF;
  color: #fff;
  border: 0;
  border-radius: 12px;
  font-size: 20px;

}*/

.custom-file-drop-check-drag{
  width: 100%;
  background: #FFFFFF;
  color: #FFFFFFFF;
  border: 2px dashed rgba(0, 153, 153, 0.4);
  border-radius: 12px;
  font-size: 20px;
}

.custom-file-drop-check-content-drag{
  background: rgba(0, 153, 153, 0.15);
  color: #FFFFFFFF;
  border: 0;
  border-radius: 12px;
  font-size: 20px;

}


.rounded-corners-dialog, .mat-mdc-dialog-container, .mat-mdc-dialog-surface {
  padding: 0;
  border-radius: 16px !important;

}


.cdk-overlay-pane {
  &.mat-tooltip-panel {
    .mat-tooltip {
      color: pink !important;
      background-color: white !important;
      border:  1px solid #eeeeee;
      border-radius:  3px;
      font-size:  15px;
      padding:  10px;
    }
  }
}

.tooltip[data-popper-placement^="bottom"] .tooltip-arrow::after {
  border-width: 1px 0 0 1px;
  border-color: #F3F4F6;
}
.tooltip[data-popper-placement^='bottom'] > .tooltip-arrow{
  top: -5px !important;
}

.tooltip[data-popper-placement^="top"] .tooltip-arrow::after {
  border-width: 0 1px 1px 0;
  border-color: #F3F4F6;
}

.tooltip[data-popper-placement^="right"] .tooltip-arrow::after {
  border-width: 0 0 1px 1px;
  border-color: #F3F4F6;
}
.tooltip[data-popper-placement^='right'] > .tooltip-arrow{
  left: -5px !important;
}

.tooltip[data-popper-placement^="left"] .tooltip-arrow::after {
  border-width: 1px 1px 0 0;
  border-color: #F3F4F6;
}

mat-expansion-panel {
  overflow: visible !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

